.logo {
    width: 7rem;
    height: 7rem;
}
.MyLogo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .MyLogo img {
    margin: 5px; 
  }
  