.headings{
    border-left: 5px solid #0098ED;
    box-sizing: border-box;
}


@media(max-width: 576px) {
    .flexdivs{
        /* height: 205px; */
        width: 307px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 8px; 
    }

    .flexbox{
        align-items: center;
    }
}

