
.App {
  font-family: "Montserrat";
  overflow-x: hidden;
}

.App {
  font-family: "Open Sans";
}

#navbar-white {
  background-color: white !important;
}

.navlinks-grey {
  color: #3f4a56;
  font-weight: 600;
}

.hrline {
  background-color: rgba(255, 255, 255, 0.295);
  height: 1px;
  width: 100%;
}

#home {
  /* background-image: url('./assets/icons/right.svg'); */
  background-repeat: no-repeat;
  background-position: right;
}

#bg-green {
  background: #1f842e;
  color: white;
}

.greytext {
  color: #3f4a56;
}

.green-heading {
  font-weight: 800 !important;
  color: #1f842e;
}

.red-heading {
  font-weight: 800 !important;
  color: #c31212;
}

.bold-text {
  font-weight: 600;
}

.extra-bold-text {
  font-weight: 800;
}

.mobicon {
  display: none;
}

.tdborder {
  border: 1px solid grey;
  width: 50% !important;
}

.tdborderbudget {
  border: 1px solid #e5e5e5;
}

.threecols {
  width: 33.33% !important;
}

.borderbtm {
  border-bottom: 1px solid grey;
}

.budget-btm {
  border-bottom: 1px solid #e5e5e5;
}

.snowidth {
  width: 5% !important;
  border: 1px solid grey;
}

.nametd {
  width: 35% !important;
  border: 1px solid grey;
}

.afftd {
  width: 55% !important;
  border: 1px solid grey;
}

.budgetbg {
  background-color: #f6f6f6 !important;
  margin-bottom: 0 !important;
}

.greenline {
  background-color: #1f842e;
  width: 40%;
  height: 4px;
  margin: auto;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.budget-table-head {
  background-color: #e5e5e5;
}

.budget-btm td {
  background-color: white !important;
}

.right-text {
  text-align: right;
}

p {
  text-align: justify;
}

.tdborderequal {
  width: 50% !important;
}

.target::before {
  content: "";
  display: block;
  height: 80px;
  margin: -50px 0 0;
}

#navbar-white {
  background-color: #1f842e !important;
  /* height: 60px; */
}

#footer {
  background-image: url("./assets/icons/footer.svg");
  background-size: cover;
  margin-top: 0 !important;
  min-height: 150px;
  background-repeat: no-repeat;
  padding-left: 24px;
}

#foot {
  width: 60% !important;
}

#partnerText{
  font-size: 14px !important;
}
.navlinks-top:hover,
navlinks-top:active {
  color: rgb(218, 218, 218) !important;
}

.plain {
  height: 100px;
}

.navlinks-top {
  color: white !important;
}

.si {
  text-decoration: none !important;
  color: #fff;
}

.si:hover {
  color: #3f95ea;
}

.greenhead {
  color: #1f842e;
}

.silogo {
  height: 1.1rem;
  margin-left: 0.6rem;
}

.smallimg {
  max-width: 70%;
  height: auto;
}

.content-down {
  padding-top: 200px;
  background: red;
}

.nayi {
  height: auto;
  width: 30%;
  display: block;
}

.tableNew {
  border: #1f1f1f 1px solid;
}
.tableNew td {
  border: #1f1f1f 1px solid;
  border-bottom: none;
  border-top: none;
  /* padding: 10px 20px; */
}

@media only screen and (max-width: 1350px) {
  #foot {
    width: 65% !important;
  }
}

@media only screen and (max-width: 1000px) {
  #foot {
    flex-direction: column-reverse !important;
    width: 100% !important;
    padding: 0 !important;
  }

  #partner {
    margin-top: 0 !important;
  }

  #footer {
    background-color: #1f1f1f;
    padding-left: 4px !important;
  }
}

@media only screen and (min-width: 425px) {
  .smallimg {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .si-text {
    font-size: 12px;
  }

  .naya {
    width: 25%;
    margin: 0;
    padding: 0;
  }

  .foottext {
    font-size: 14px;
  }

  .silogo {
    height: 1rem;
  }

  .green-heading {
    font-size: 24px;
  }
}

@media only screen and (max-width: 360px) {
  .si-text {
    font-size: 10px;
  }

  .foottext {
    font-size: 12px;
  }

  .silogo {
    height: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  #footer {
    background-color: #1f1f1f;
  }

  .foothandle {
    min-height: calc(100vh - 150px);
  }

  .target::before {
    content: "";
    display: block;
    height: 320px;
    margin: -300px 0 0;
  }
}

@media only screen and (max-width: 1000px) {
  .mobicon {
    display: block !important;
  }

  .deskicon {
    display: none !important;
  }
}

@media only screen and (max-width: 425px) {
}
