.partners-table {
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;
}

.partners-table td {
    padding: 0;
    vertical-align: middle;
    text-align: center;
}

.partner-image {
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin: 0 auto;
}



@media (max-width: 768px) {

    .partners-table,
    .partners-table tbody,
    .partners-table tr,
    .partners-table td {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 100%;
    }

    .partners-table td {
        margin-bottom: 20px 10px;
    }

    .partner-section-title {
        font-size: 24px;
        text-align: center;
    }

    .partner-image {
        width: 100px;
        height: 100px;
        margin: 0 auto;
    }
    .technical-partner-container {
        margin: 20px;
    }
    .financial-partner-container {
        margin: 20px;
    }
    .teri-container{
        margin: 20px;
    }
}