.KN-grid {
    display: flex;
    margin-top: 1rem;
    gap: 1.25rem;
}

.KN-container {
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
    column-gap: 2remn;
}

.KN-container img {
    width: 25rem;
    height: 25rem;
    border-radius: 8px;
    object-fit: fill;

}

.mohan {
    margin-left: 100px;
    margin-right: 30px;
}

.KN-text {
    width: 100%;
}

@media only screen and (max-width: 963px) {

    .KN-container {
        flex-direction: column;
        gap: 0;
    }

    .KN-container img {
        align-self: center;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 463px) {



    .KN-container img {
        height: 15rem;
        width: 15rem;
    }
}